.bg {
    position: absolute;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background-color: white;
    background-size: 25px 25px, 25px 25px, 5px 5px, 5px 5px;
    background-position: -2px -2px, -2px -2px, -2px -2px, -2px -2px;
    background-image: linear-gradient(#ebebeb 1px, transparent 0px), linear-gradient(90deg, #ebebeb 1px, transparent 0px), linear-gradient(#f7f7f7 1px, transparent 0px), linear-gradient(90deg, #f7f7f7 1px, transparent 0px);
}

.map {
    position: absolute;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    /* box-shadow: inset 10px 10px 5px 200px rgba(0,0,0,0.34); */
    z-index: 1;
}

/* .leaflet-container {
    height: 400px;
    width: 100%;
  } */

.vis-network {
    z-index: 999999;
}

.card1 {
    border-radius: 2px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .3), 0 0 0 1px rgba(63, 63, 68, .1);
    background-color: #fff;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
    position: relative;
    max-width: 250px;
}
linespace {
    margin-bottom: 10px;
}
.linecard1 {
    padding-right: 0;
    padding-left: 0;
    border-bottom: 1px solid #ddd;
    margin: 0;
    margin-bottom: 0px;
}

.linecard2{
    padding-right: 0;
    padding-left: 0;
    border-bottom: 1px dashed #999;
    margin: 0;

}
.lineDataEntity {
    display: flex;
    width: 90%;
    background: white;
    margin: 10px auto;
    height: 50px;
    justify-content: space-between;
    align-items: center;
}
.titreentity {
    font-family: Roboto, Helvetica Neue, Arial, sans-serif;
    padding: 8px 8px 8px 0px;
    text-decoration: none;
    font-size: 12px;
    color: #9120c1;
    display: block;
    transition: 0.3s;
    line-height: 1.5;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    flex:5;
}

.textentity {
    font-family: Roboto, Helvetica Neue, Arial, sans-serif;
    font-size: 12px;
    text-decoration: none;
    color: #624b6f;
    display: block;
    transition: 0.3s;
    line-height: 1.5;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
    margin-bottom: 10px;
    text-align: center;
    position: relative;
    height: 22px;
}


.gg-chevron-left-o {
    box-sizing: border-box;
    display: block;
    transform: scale(var(--ggs,1.5));
    width: 22px;
    height: 22px;
    border: 2px solid;
    background-color:#fdbc8c;
    margin: 0 auto;
    /*position:absolute;*/
    /*margin-top:10px;*/
    /*right:350px;*/

}

.gg-chevron-left-o::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 6px;
    height: 6px;
    border-bottom: 2px solid;
    border-left: 2px solid;
    transform: rotate(-135deg);
    left: 5px;
    top: 6px;

}

.gg-chevron-right-o {
    box-sizing: border-box;
    display: block;
    transform: scale(var(--ggs,1.5));
    width: 22px;
    height: 22px;
    border: 2px solid;
    background-color:#5ddd71;
    margin: 0 auto;
    /*position:absolute;*/
    /*margin-top:10px;*/
    left:50px;

}

.gg-chevron-right-o::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 6px;
    height: 6px;
    border-bottom: 2px solid;
    border-left: 2px solid;
    transform: rotate(-135deg);
    left: 5px;
    top: 6px;

}

.gg-chevron-center-o {
    box-sizing: border-box;

    display: block;
    transform: scale(var(--ggs,1.5));
    width: 22px;
    height: 22px;
    border: 2px solid;
    position:absolute;
    background-color:red;
    position:absolute;
    /*margin-top:5px;*/
    left:240px;

}

.gg-chevron-center-o::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 6px;
    height: 6px;
    border-bottom: 2px solid;
    border-left: 2px solid;
    transform: rotate(-135deg);
    left: 5px;
    top: 6px;

}