.root:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgb(39 51 62 / 36%);
    opacity: 1;
    z-index: 0;
}

.root:hover {
cursor: pointer;
transform: scale(1.05);
transition: transform .2s;
// border: 1px solid purple;
}

#tsparticles {
    position: absolute;
    width: 100%;
    height: calc(100vh - 20rem);
}
